import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import useIntercom from "../../old/hooks/useIntercom"

export const useLearningPage = () => {
  useIntercom().shutdownIntercom()

  const { t } = useTranslation("learningPage")
  const [searchParams] = useSearchParams()
  const phishingId = searchParams.get("Id")

  const redirect = phishingId === null ? "/phishing" : `/phishing/${phishingId}`
  const navigate = useNavigate()
  return { t, navigate, redirect }
}
