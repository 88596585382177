import { flagsDictionary } from "@kamae-apps/shared/Components/Fondations/Flags/FlagsDictonnary"
import { FC } from "react"
export type FlagVariants = "fr" | "en" | "es" | "de"
type FlagSizes = "s" | "m"
type FlagsProps = {
  variant: FlagVariants
  size?: FlagSizes
}
const flagSizeToClasses = Object.freeze({
  s: "w-4 h-3",
  m: "w-13 h12",
})
export const Flag: FC<FlagsProps> = ({ variant, size = "s" }) => {
  const path = flagsDictionary[variant]
  const sizeClass = flagSizeToClasses[size]
  return (
    <svg
      className={sizeClass}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {path}
    </svg>
  )
}
