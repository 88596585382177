import Icon from "@kamae-apps/shared/Components/Fondations/Icons/Icon"
import { DropdownMenu, type DropdownMenuEntries } from "@kamae-apps/shared/Components/Molecules/Dropdown/Menu/Menu"
import useClickOutside from "@kamae-apps/shared/Hooks/useClickOutside"
import useBreakpoints from "@kamae-apps/shared/Hooks/useMediaQuery"
import { getBeltColor } from "@kamae-apps/shared/Types/Belt/Belt"

import { RoundAvatar } from "@kamae-apps/shared/Components/Atoms/RoundAvatar/RoundAvatar"
import type TUser from "@kamae-apps/shared/Types/TUser"
import clsx from "clsx"
import { FC, useRef, useState } from "react"
import config from "../../../variable"

type UserMenuProps = {
  user: TUser
  entries: DropdownMenuEntries[]
  getRoundedAvatarUrl: (avatar?: string) => string
}

export const UserMenu: FC<UserMenuProps> = (props: UserMenuProps) => {
  const userMenuRef = useRef<HTMLDivElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  useClickOutside(userMenuRef, () => setIsMenuOpen(false))

  return (
    <div
      ref={userMenuRef}
      className="relative"
    >
      <button
        className={clsx(
          "flex w-13 h-13 items-center gap-3 rounded-xl py-3 px-2.5 rebranding-sm:w-12 lg:w-full lg:px-5",
          isMenuOpen
            ? "bg-neutral-gray-50 rebranding-sm:bg-white bg-opacity-100"
            : "bg-white bg-opacity-30 hover:bg-opacity-50"
        )}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        type="button"
      >
        <RoundAvatar
          imgSrc={props.getRoundedAvatarUrl(props.user.avatar)}
          imgAlt={`${props.user.avatar}_rounded`}
          size={"s"}
        />
        <p className="hidden caption-regular lg:block">{props.user.first_name}</p>
      </button>
      {isMenuOpen && (
        <div className="absolute right-0 bottom-14 rebranding-sm:bottom-0 rebranding-sm:left-13 lg:left-51">
          <DropdownMenu
            entries={props.entries}
            onCloseMenu={() => setIsMenuOpen(false)}
          />
        </div>
      )}
    </div>
  )
}

type AvatarDisplayProps = {
  user: TUser
}
const AvatarDisplay: FC<AvatarDisplayProps> = ({ user }) => {
  const { isMobile } = useBreakpoints()
  const [avatarError, setAvatarError] = useState(false)
  const avatarSource = `${config.api}/avatar/${user.avatar}/${getBeltColor(user.level)}`

  if (isMobile) {
    return (
      <Icon
        name="dotsHor"
        size="m"
      />
    )
  }
  if (avatarError || !avatarSource) {
    return (
      <Icon
        name="man"
        size="m"
      />
    )
  }
  return (
    <img
      src={avatarSource}
      alt="Avatar"
      onError={() => setAvatarError(true)}
      className="object-cover w-full h-full"
    />
  )
}
