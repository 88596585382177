import { FC, useState } from "react"
import NewBeltModal from "../NewBeltModal/NewBeltModal"
import { NewsBarHandler } from "../NewsBarHandler/NewsBarHandler"
import { UserType, WelcomeModalRouter } from "../WelcomeModal/WelcomeModalRouter"

type MessagePortalProps = {
  isNewsBarActive: boolean
  displayNewBeltModal: boolean
  setDisplayNewBeltModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const MessagePortal: FC<MessagePortalProps> = ({
  isNewsBarActive,
  displayNewBeltModal,
  setDisplayNewBeltModal,
}) => {
  const [usertype, setUsertype] = useState<UserType | null>(null)
  return (
    <>
      {isNewsBarActive && <NewsBarHandler setUsertype={setUsertype} />}
      {displayNewBeltModal && <NewBeltModal close={() => setDisplayNewBeltModal(false)} />}
      <WelcomeModalRouter
        usertype={usertype}
        setUsertype={setUsertype}
      />
    </>
  )
}
