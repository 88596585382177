import { useAuth0 } from "@auth0/auth0-react"
import { type DropdownMenuEntries } from "@kamae-apps/shared/Components/Molecules/Dropdown/Menu/Menu"
import useBreakpoints from "@kamae-apps/shared/Hooks/useMediaQuery"
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus"
import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope"
import { useCryptr } from "@killiangabrielkamae/cryptr-react"
import type { TFunction } from "i18next"
import posthog from "posthog-js"
import { useCallback, useMemo, useState } from "react"
import { useAuth } from "react-oidc-context"
import { useNavigate } from "react-router-dom"
import { useUser } from "../../../Contexts/User/UserContext"
import { useAvatars } from "../../../hooks/useAvatars"
import { useFeatureFlags } from "../../../old/hooks/posthog/useFeatureFlags"
import config from "../../../variable"

export const useMenu = (t: TFunction) => {
  const auth0 = useAuth0()
  const cryptr = useCryptr()
  const oidcAxa = useAuth()
  const { user } = useUser()
  const navigate = useNavigate()
  const { isMobile } = useBreakpoints()
  const { getAvatarUrl, getRoundedAvatarUrl } = useAvatars()
  const isAdmin = scopeHas(user.scope, Scope.COMPANY_ADMIN)
  const isTeamLeader = user.id === user.team.leader?.id
  const isStarterCompany = user.company.status === CompanyStatus.Discovery
  const [starterModal, setStarterModal] = useState(false)

  const logOut = useCallback(() => {
    posthog.reset()
    localStorage.clear()
    if (cryptr.isAuthenticated()) {
      cryptr.logOut(undefined, `${config.app}/login`, false)
    } else if (auth0.isAuthenticated) {
      auth0.logout({ returnTo: `${window.location.origin}/login` })
    } else if (oidcAxa.isAuthenticated) {
      // Remove local session only (do not SLO)
      oidcAxa.removeUser()
      window.location.assign("/login")
    }
  }, [auth0, cryptr, oidcAxa])

  const baseMenuEntries: DropdownMenuEntries[] = useMemo(() => {
    const entries: DropdownMenuEntries[] = [
      {
        content: {
          displayNameKey: t("app:userMenu.avatar"),
          iconName: "pen",
          onClick: () => navigate("/profile"),
        },
      },
      { divider: true },
      {
        content: {
          isDanger: true,
          displayNameKey: t("app:userMenu.logout"),
          iconName: "logOut",
          onClick: logOut,
        },
      },
    ]
    return entries
  }, [t, navigate, logOut])

  const adminMenuEntries: DropdownMenuEntries[] = useMemo(
    () =>
      isAdmin && isMobile
        ? [
            {
              content: {
                onClick: () => navigate("/organization"),
                iconName: "dashboard",
                displayNameKey: t("app:sidebar.organization.label"),
              },
            },
            {
              content: {
                onClick: () => navigate("/supervision"),
                iconName: "supervision",
                displayNameKey: t("app:sidebar.supervision.label"),
              },
            },
            {
              content: {
                onClick: () => navigate("/team"),
                iconName: "manGroup",
                displayNameKey: t("app:sidebar.team.label"),
              },
            },
          ]
        : [],
    [isAdmin, isMobile, navigate, t]
  )
  const userMenuEntries: DropdownMenuEntries[] = useMemo(
    () => [...adminMenuEntries, ...baseMenuEntries],
    [adminMenuEntries, baseMenuEntries]
  )

  return {
    user,
    isAdmin,
    isTeamLeader,
    userMenuEntries,
    getAvatarUrl,
    getRoundedAvatarUrl,
    isStarterCompany,
    starterModal,
    setStarterModal,
  }
}
