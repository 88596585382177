import useBreakpoints from "@kamae-apps/shared/Hooks/useMediaQuery"
import clsx from "clsx"
import { FC, ReactNode } from "react"
import style from "./Display.module.css"

type DisplayModalProps = {
  display: boolean
  title: string | ReactNode
  subTitle: string
  primaryButton?: ReactNode
  secondaryButton?: ReactNode
  children: ReactNode
}

export const DisplayModal: FC<DisplayModalProps> = ({
  display,
  title,
  subTitle,
  primaryButton,
  secondaryButton,
  children,
}) => {
  const { isMobile } = useBreakpoints()
  return (
    <div
      className={clsx(style["modal-container"], isMobile && "overflow-y-auto")}
      data-display={display}
    >
      <div
        className={style["modal-md"]}
        onClick={e => e.stopPropagation()}
      >
        <div className={style["modal-header"]}>
          <div className={style["modal-header-content"]}>
            <div className={style["modal-header-content-title"]}>
              <h3>{title}</h3>
            </div>
            <p className={style["modal-header-content-subtitle"]}>{subTitle}</p>
          </div>
        </div>
        <div className={style["modal-content"]}>{children}</div>
        <div className={style["modal-footer"]}>
          {secondaryButton}
          {primaryButton}
        </div>
      </div>
    </div>
  )
}
