import {
  BeltDescriptionProps,
  BeltVariants,
} from "@kamae-apps/shared/Components/Molecules/BeltDescription/BeltDescription"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useFeatureFlags } from "../old/hooks/posthog/useFeatureFlags"

const STANDARD_BELT: Array<{ level: number; variant: BeltVariants }> = [
  { level: 0, variant: BeltVariants.WHITE },
  { level: 1, variant: BeltVariants.YELLOW },
  { level: 2, variant: BeltVariants.ORANGE },
  { level: 3, variant: BeltVariants.GREEN },
  { level: 4, variant: BeltVariants.BLUE },
  { level: 5, variant: BeltVariants.PURPLE },
  { level: 6, variant: BeltVariants.BROWN },
  { level: 7, variant: BeltVariants.BLACK },
]

const OTHER_BELT: Array<{ level: number; variant: BeltVariants }> = [
  { level: 8, variant: BeltVariants.RED },
  { level: 9, variant: BeltVariants.BRONZE },
  { level: 10, variant: BeltVariants.SILVER },
  { level: 11, variant: BeltVariants.GOLD },
]

export const useBelts = () => {
  const { t } = useTranslation("belts")
  const featureFlags = useFeatureFlags()

  const showAllBelts = featureFlags.includes("show-all-belts")
  const belts = showAllBelts ? STANDARD_BELT.concat(OTHER_BELT) : STANDARD_BELT

  const beltMap = useMemo(() => {
    const map: Record<number, BeltDescriptionProps> = {}

    for (const belt of belts) {
      const color = belt.variant.replace("belt-", "")
      map[belt.level] = {
        variant: belt.variant,
        levelText: t(`belts:variants.${color}.level`),
        name: t(`belts:variants.${color}.name`),
      }
    }

    return map
  }, [t, belts])

  return {
    beltList: Object.entries(beltMap).map(([_, beltInfo]) => beltInfo),
    getBeltByLevel: (level: number): BeltDescriptionProps => beltMap[level],
  }
}
