import { Button } from "@kamae-apps/shared/Components/Atoms/Button/Button"
import { Modal } from "@kamae-apps/shared/Components/Molecules/Modal/Standard/Standard"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import config from "../../../variable"

type StarterModalProps = {
  isDisplayed: boolean
  close: () => void
}

export const StarterModal: FC<StarterModalProps> = ({ isDisplayed, close }) => {
  const { t } = useTranslation("discoveryLicence")

  return (
    <Modal
      variant={"modal-md"}
      display={isDisplayed}
      title={t("discoveryLicence:rebranding.modal.modalTitle")}
      secondaryButton={
        <Button
          variant={"button-primary-initial-default"}
          onClick={() => window.open(config.kamaeEssentialUrl, "_blank")}
        >
          {t("discoveryLicence:rebranding.modal.discover")}
        </Button>
      }
      close={close}
    >
      <div className="body-sm-regular">
        <p>{t("discoveryLicence:rebranding.modal.title")}</p>
        <p>{t("discoveryLicence:rebranding.modal.subTitle")}</p>
        <ul className="list-disc pl-6 pt-2 space-y-1">
          <li className="relative">{t("discoveryLicence:rebranding.modal.arg1")}</li>
          <li className="relative">{t("discoveryLicence:rebranding.modal.arg2")}</li>
          <li className="relative">{t("discoveryLicence:rebranding.modal.arg3")}</li>
          <li className="relative">{t("discoveryLicence:rebranding.modal.arg4")}</li>
          <li className="relative">{t("discoveryLicence:rebranding.modal.arg5")}</li>
        </ul>
      </div>
    </Modal>
  )
}
