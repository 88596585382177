import { posthog } from "posthog-js"
import { useEffect, useState } from "react"

const featureFlagSaved = new Set<string>()

export function useFeatureFlags(): string[] {
  const [_, setFeatureFlags] = useState<string[]>([])
  useEffect(() => {
    posthog.onFeatureFlags(flags => {
      // Here, we verify if groups is set :
      // We know it only happens when user is fully loaded ( App.tsx l292 )
      // So we can safely set the feature flags because they will have
      // been loaded fully ( and not just the auto rollout ones )
      if (flags && posthog.getGroups()["Company"] != null) {
        flags.forEach(flag => featureFlagSaved.add(flag))
        setFeatureFlags(flags)
      }
    })
  }, [])

  return [...featureFlagSaved]
}
