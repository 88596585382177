import Icon from "@kamae-apps/shared/Components/Fondations/Icons/Icon"
import { IconNames } from "@kamae-apps/shared/Components/Fondations/Icons/IconNames.type"
import { FC, MouseEventHandler } from "react"
import style from "./IconButton.module.css"
import {
  IconButtonVariantsDanger,
  IconButtonVariantsDisabled,
  IconButtonVariantsInitial,
  IconButtonVariantsLight,
  IconButtonVariantsSuccess,
} from "./IconButton.type"

type IconButtonVariants =
  | IconButtonVariantsInitial
  | IconButtonVariantsDanger
  | IconButtonVariantsSuccess
  | IconButtonVariantsLight
  | IconButtonVariantsDisabled

type IconButtonSize = "m" | "s"

type IconButtonVariantProps = {
  disabledVariant: IconButtonVariantsDisabled
  size: IconButtonSize
}

const iconButtonVariantToDisabled: Record<IconButtonVariants, IconButtonVariantProps> = Object.freeze({
  "button-default-initial": { disabledVariant: "button-default-disabled", size: "m" },
  "button-default-danger": { disabledVariant: "button-default-disabled", size: "m" },
  "button-default-success": { disabledVariant: "button-default-disabled", size: "m" },
  "button-default-light": { disabledVariant: "button-default-disabled", size: "m" },
  "button-default-disabled": { disabledVariant: "button-default-disabled", size: "m" },
  "button-small-initial": { disabledVariant: "button-small-disabled", size: "s" },
  "button-small-danger": { disabledVariant: "button-small-disabled", size: "s" },
  "button-small-success": { disabledVariant: "button-small-disabled", size: "s" },
  "button-small-light": { disabledVariant: "button-small-disabled", size: "s" },
  "button-small-disabled": { disabledVariant: "button-small-disabled", size: "s" },
})

type IconButtonProps = {
  variant: IconButtonVariants
  icon: IconNames
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

export const IconButton: FC<IconButtonProps> = ({ variant, icon, onClick, disabled }) => {
  const { disabledVariant, size } = iconButtonVariantToDisabled[variant]
  const iconButtonClass = disabled ? disabledVariant : variant

  return (
    <button
      type={"button"}
      onClick={onClick}
      disabled={disabled}
      className={style[iconButtonClass]}
    >
      <Icon
        name={icon}
        size={size}
      />
    </button>
  )
}
