import { Button } from "@kamae-apps/shared/Components/Atoms/Button/Button"
import { DisplayModal } from "@kamae-apps/shared/Components/Molecules/Modal/Display/Display"
import useMediaQuery from "@kamae-apps/shared/Hooks/useMediaQuery"
import { useStep } from "@kamae-apps/shared/Hooks/useStep"
import { TFunction } from "i18next"
import type { FC, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

type WelcomeModalV1UserProps = {
  close: () => void
}

type StepData = {
  title: string | ReactNode
  subTitle: string
  nextButton: string
  previousButton?: string
  imageSrc: string
  imageAlt: string
  imageClassName?: string
}

export const WelcomeModalV1User: FC<WelcomeModalV1UserProps> = ({ close }) => {
  const navigate = useNavigate()
  const { t } = useTranslation("app")
  const { isMobile } = useMediaQuery()

  const { currentStepData, onNext, onPrevious } = useStep<StepData>({
    steps: getStepData(t, isMobile),
    onComplete: () => {
      close()
      navigate("/profile")
    },
  })

  return (
    <Step
      stepData={currentStepData}
      onNext={onNext}
      onPrevious={onPrevious}
    />
  )
}

const getStepData = (t: TFunction, isMobile: boolean): StepData[] => [
  {
    title: t("app:welcomeModalV1User.step1.title"),
    subTitle: t("app:welcomeModalV1User.step1.subTitle"),
    nextButton: t("app:welcomeModalV1User.step1.nextButton"),
    imageSrc: `/animation/old_new${isMobile ? "_mobile" : ""}.gif`,
    imageAlt: "old_new",
  },
  {
    title: t("app:welcomeModalV1User.step2.title"),
    subTitle: t("app:welcomeModalV1User.step2.subTitle"),
    nextButton: t("app:welcomeModalV1User.step2.nextButton"),
    previousButton: t("app:welcomeModalV1User.step2.previousButton"),
    imageSrc: `/animation/navigation${isMobile ? "_mobile" : ""}.gif`,
    imageAlt: "navigation",
  },
  {
    title: t("app:welcomeModalV1User.step3.title"),
    subTitle: t("app:welcomeModalV1User.step3.subTitle"),
    nextButton: t("app:welcomeModalV1User.step3.nextButton"),
    previousButton: t("app:welcomeModalV1User.step3.previousButton"),
    imageSrc: `/animation/training${isMobile ? "_mobile" : ""}.gif`,
    imageAlt: "training",
  },
  {
    title: t("app:welcomeModalV1User.step4.title"),
    subTitle: t("app:welcomeModalV1User.step4.subTitle"),
    nextButton: t("app:welcomeModalV1User.step4.nextButton"),
    previousButton: t("app:welcomeModalV1User.step4.previousButton"),
    imageSrc: `/new_avatar${isMobile ? "_mobile" : ""}.png`,
    imageAlt: "new_avatar",
  },
]

type StepProps = {
  stepData: StepData
  onNext: () => void
  onPrevious?: () => void
}

const Step: FC<StepProps> = ({ stepData, onNext, onPrevious }) => {
  const { isMobile } = useMediaQuery()
  const { title, subTitle, nextButton, previousButton, imageSrc, imageAlt } = stepData

  return (
    <DisplayModal
      display
      title={title}
      subTitle={subTitle}
      primaryButton={
        <Button
          variant="button-primary-initial-default"
          onClick={onNext}
        >
          {nextButton}
        </Button>
      }
      secondaryButton={
        onPrevious && (
          <Button
            variant="button-secondary-initial-default"
            onClick={onPrevious}
          >
            {previousButton}
          </Button>
        )
      }
    >
      <img
        className={`${isMobile ? "h-96 w-132" : "h-75 w-133"} m-auto border border-neutral-gray-300 rounded-xl`}
        src={imageSrc}
        alt={imageAlt}
      />
    </DisplayModal>
  )
}
