import { Button } from "@kamae-apps/shared/Components/Atoms/Button/Button"
import { NewsBar } from "@kamae-apps/shared/Components/Molecules/NewsBar/NewsBar"
import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useUser } from "../../Contexts/User/UserContext"
import { fetchUserV1Data } from "../../Contexts/User/userUseCases"
import config from "../../variable"
import { UserType } from "../WelcomeModal/WelcomeModalRouter"

type NewsBarHandlerProps = {
  setUsertype: (usertype: UserType) => void
}

export const NewsBarHandler: FC<NewsBarHandlerProps> = ({ setUsertype }) => {
  const { user } = useUser()
  const { t } = useTranslation("app")

  const handleFeedbackClick = () => {
    const typeformUrlId = isUserV1 ? "T4XhjSOX" : "CexYBKki"
    const companyId = (config.env === "production" && user?.company?.id) || "xxxxx"
    const userId = (config.env === "production" && user?.id) || "xxxxx"
    const isAdmin = scopeHas(user?.scope, Scope.COMPANY_ADMIN) ? 1 : 0
    const typeformUrl = `https://form.typeform.com/to/${typeformUrlId}#company_id=${companyId}&user_id=${userId}&is_admin=${isAdmin}`
    window.open(typeformUrl, "_blank", "noopener,noreferrer")
  }

  const [isUserV1, setIsUserV1] = useState(false)

  useEffect(() => {
    fetchUserV1Data().then(user => setIsUserV1(!!user.avatar))
  }, [])

  return (
    <>
      <NewsBar
        title={t(`app:newsBarModal.${isUserV1 ? "titleUserV1" : "title"}`)}
        primaryButton={
          <Button
            variant="button-primary-initial-small"
            onClick={handleFeedbackClick}
          >
            {t("app:newsBarModal.primaryButton")}
          </Button>
        }
        secondaryButton={
          <Button
            variant="button-secondary-light-small"
            onClick={() => setUsertype(isUserV1 ? UserType.V1 : UserType.V2)}
          >
            {t("app:newsBarModal.secondaryButton")}
          </Button>
        }
      />
    </>
  )
}
