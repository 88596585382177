import { InfoTooltip } from "@kamae-apps/shared/Components/Molecules/InfoTooltip/InfoTooltip"
import clsx from "clsx"
import { FC, MouseEventHandler, TouchEventHandler, useState } from "react"
import style from "./Tooltip.module.css"

export type TooltipPosition = "tooltip-top" | "tooltip-right" | "tooltip-bottom" | "tooltip-left"

type TooltipProps = {
  children: JSX.Element | JSX.Element[]
  tooltipPosition: TooltipPosition
  title: string
  className?: string
}

export const Tooltip: FC<TooltipProps> = ({ children, tooltipPosition, title, className }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleMouseEnter: MouseEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    setIsVisible(true)
  }

  const handleMouseLeave: MouseEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    setIsVisible(false)
  }

  const handleTouchStart: TouchEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    setIsVisible(true)
  }

  const handleTouchEnd: TouchEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    setIsVisible(false)
  }

  return (
    <div
      className={clsx(className, style["tooltip-parent"])}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
    >
      {children}
      {isVisible && (
        <TooltipItem
          title={title}
          tooltipPosition={tooltipPosition}
        />
      )}
    </div>
  )
}

type TooltipItemProps = {
  title: string
  tooltipPosition: TooltipPosition
}

const TooltipItem: FC<TooltipItemProps> = ({ title, tooltipPosition }) => {
  return (
    <div className={clsx(style["tooltip-container"], style[tooltipPosition])}>
      <p className={style["tooltip-title"]}>{title}</p>
    </div>
  )
}
