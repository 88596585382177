import { Card } from "@kamae-apps/shared/Components/Atoms/Card/Card"
import { Flag, FlagVariants } from "@kamae-apps/shared/Components/Fondations/Flags/Flags"
import Icon from "@kamae-apps/shared/Components/Fondations/Icons/Icon"
import { ConditionalRender } from "@kamae-apps/shared/old/Component/ConditionalRender/ConditionalRender"
import { DropDown } from "@kamae-apps/shared/old/Component/DropDown/DropDown"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useFeatureFlags } from "../../../old/hooks/posthog/useFeatureFlags"

interface Langue {
  flag: string
  code: string
  name: string
}

interface FlagItemProps extends Langue {
  setLang?: (v: string) => void
  isSelected: boolean
}

function FlagItem(props: FlagItemProps) {
  return (
    // biome-ignore lint/a11y/useFocusableInteractive: <explanation>
    <div
      // biome-ignore lint/a11y/useSemanticElements: <explanation>
      role="button"
      onClick={() => {
        props.setLang?.(props.code)
      }}
      title={props.name}
      className={"p-1 flex items-center gap-6"}
    >
      <div className={"flex gap-2 items-center"}>
        <Flag
          variant={props.code as FlagVariants}
          size={"s"}
        />
        <p className={clsx(props.isSelected ? "body-sm-semibold" : "body-sm-regular")}>{props.name}</p>
      </div>
      {props.isSelected && (
        <div className={"text-primary-kamae-500"}>
          <Icon name={"check"} />
        </div>
      )}
    </div>
  )
}
interface FlagProps {
  allLangs?: boolean
}
export default function FlagDropdown(props: FlagProps) {
  const { i18n } = useTranslation()
  const featureFlags = useFeatureFlags()

  const languages = [
    { flag: "🇫", code: "fr", name: "Français" },
    { flag: "🇬", code: "en", name: "English" },
  ]

  if (featureFlags.includes("spanish") || props.allLangs) {
    languages.push({ flag: "🇪", code: "es", name: "Spanish" })
  }

  if (featureFlags.includes("german") || props.allLangs) {
    languages.push({ flag: "🇩", code: "de", name: "German" })
  }

  const selectedFlag = languages.find(lang => lang.code === i18n.language) ?? languages[0]
  return (
    <Card
      className={
        "!overflow-visible !border-neutral-gray-300 [&_[data-rebranding=dropdown-body]]:right-0 [&_[data-rebranding=dropdown-body]]:border-neutral-gray-300 [&_[data-rebranding=dropdown-body]]:w-fit [&_[data-rebranding=dropdown-body]]:mt-1"
      }
    >
      <DropDown
        className={"!right-0"}
        title={
          <div className={"p-1"}>
            <Flag
              variant={selectedFlag.code as FlagVariants}
              size={"s"}
            />
          </div>
        }
      >
        <div className="flex flex-col">
          {languages.map((v, i) => (
            <FlagItem
              key={v.code}
              isSelected={v.code === selectedFlag.code}
              setLang={i18n.changeLanguage}
              {...v}
            />
          ))}
        </div>
      </DropDown>
    </Card>
  )
}
