import Icon from "@kamae-apps/shared/Components/Fondations/Icons/Icon"
import { IconNames } from "@kamae-apps/shared/Components/Fondations/Icons/IconNames.type"
import { FC } from "react"
import style from "./Tag.module.css"

export type TagVariants =
  | "tag-default-on-going"
  | "tag-default-error"
  | "tag-default-warning"
  | "tag-default-success"
  | "tag-default-disabled"
  | "tag-default-soft-purple"
  | "tag-default-turquoise"
  | "tag-default-soft-pink"
  | "tag-default-orange"
  | "tag-default-pink"
  | "tag-default-purple"
  | "tag-default-blue"
  | "tag-default-yellow"
  | "tag-small-on-going"
  | "tag-small-error"
  | "tag-small-warning"
  | "tag-small-success"
  | "tag-small-disabled"
  | "tag-small-soft-purple"
  | "tag-small-turquoise"
  | "tag-small-soft-pink"
  | "tag-small-orange"
  | "tag-small-pink"
  | "tag-small-purple"
  | "tag-small-blue"
  | "tag-small-yellow"
  | "tag-default-belt-white"
  | "tag-default-belt-orange"
  | "tag-default-belt-yellow"
  | "tag-default-belt-green"
  | "tag-default-belt-blue"
  | "tag-default-belt-purple"
  | "tag-default-belt-brown"
  | "tag-default-belt-black"
  | "tag-default-belt-red"
  | "tag-default-belt-bronze"
  | "tag-default-belt-silver"
  | "tag-default-belt-gold"
  | "tag-small-belt-white"
  | "tag-small-belt-orange"
  | "tag-small-belt-yellow"
  | "tag-small-belt-green"
  | "tag-small-belt-blue"
  | "tag-small-belt-purple"
  | "tag-small-belt-brown"
  | "tag-small-belt-black"
  | "tag-small-belt-red"
  | "tag-small-belt-bronze"
  | "tag-small-belt-silver"
  | "tag-small-belt-gold"

type TagDefaultColors =
  | "on-going"
  | "error"
  | "warning"
  | "success"
  | "disabled"
  | "soft-purple"
  | "turquoise"
  | "soft-pink"
  | "orange"
  | "pink"
  | "purple"
  | "blue"
  | "yellow"
export type TagBeltColors =
  | "belt-white"
  | "belt-orange"
  | "belt-yellow"
  | "belt-green"
  | "belt-blue"
  | "belt-purple"
  | "belt-brown"
  | "belt-black"
  | "belt-red"
  | "belt-bronze"
  | "belt-silver"
  | "belt-gold"

type TagColors = TagDefaultColors | TagBeltColors
type TagSizes = "s" | "xs"

type TagVariantProps = {
  color: TagColors
  size: TagSizes
}

const tagVariantToProps: Record<TagVariants, TagVariantProps> = Object.freeze({
  "tag-default-on-going": { color: "on-going", size: "s" },
  "tag-default-error": { color: "error", size: "s" },
  "tag-default-warning": { color: "warning", size: "s" },
  "tag-default-success": { color: "success", size: "s" },
  "tag-default-disabled": { color: "disabled", size: "s" },
  "tag-default-soft-purple": { color: "soft-purple", size: "s" },
  "tag-default-turquoise": { color: "turquoise", size: "s" },
  "tag-default-soft-pink": { color: "soft-pink", size: "s" },
  "tag-default-orange": { color: "orange", size: "s" },
  "tag-default-pink": { color: "pink", size: "s" },
  "tag-default-purple": { color: "purple", size: "s" },
  "tag-default-blue": { color: "blue", size: "s" },
  "tag-default-yellow": { color: "yellow", size: "s" },
  "tag-small-on-going": { color: "on-going", size: "xs" },
  "tag-small-error": { color: "error", size: "xs" },
  "tag-small-warning": { color: "warning", size: "xs" },
  "tag-small-success": { color: "success", size: "xs" },
  "tag-small-disabled": { color: "disabled", size: "xs" },
  "tag-small-soft-purple": { color: "soft-purple", size: "xs" },
  "tag-small-turquoise": { color: "turquoise", size: "xs" },
  "tag-small-soft-pink": { color: "soft-pink", size: "xs" },
  "tag-small-orange": { color: "orange", size: "xs" },
  "tag-small-pink": { color: "pink", size: "xs" },
  "tag-small-purple": { color: "purple", size: "xs" },
  "tag-small-blue": { color: "blue", size: "xs" },
  "tag-small-yellow": { color: "yellow", size: "xs" },
  "tag-default-belt-white": { color: "belt-white", size: "s" },
  "tag-default-belt-orange": { color: "belt-orange", size: "s" },
  "tag-default-belt-yellow": { color: "belt-yellow", size: "s" },
  "tag-default-belt-green": { color: "belt-green", size: "s" },
  "tag-default-belt-blue": { color: "belt-blue", size: "s" },
  "tag-default-belt-purple": { color: "belt-purple", size: "s" },
  "tag-default-belt-brown": { color: "belt-brown", size: "s" },
  "tag-default-belt-black": { color: "belt-black", size: "s" },
  "tag-default-belt-red": { color: "belt-red", size: "s" },
  "tag-default-belt-bronze": { color: "belt-bronze", size: "s" },
  "tag-default-belt-silver": { color: "belt-silver", size: "s" },
  "tag-default-belt-gold": { color: "belt-gold", size: "s" },
  "tag-small-belt-white": { color: "belt-white", size: "xs" },
  "tag-small-belt-orange": { color: "belt-orange", size: "xs" },
  "tag-small-belt-yellow": { color: "belt-yellow", size: "xs" },
  "tag-small-belt-green": { color: "belt-green", size: "xs" },
  "tag-small-belt-blue": { color: "belt-blue", size: "xs" },
  "tag-small-belt-purple": { color: "belt-purple", size: "xs" },
  "tag-small-belt-brown": { color: "belt-brown", size: "xs" },
  "tag-small-belt-black": { color: "belt-black", size: "xs" },
  "tag-small-belt-red": { color: "belt-red", size: "xs" },
  "tag-small-belt-bronze": { color: "belt-bronze", size: "xs" },
  "tag-small-belt-silver": { color: "belt-silver", size: "xs" },
  "tag-small-belt-gold": { color: "belt-gold", size: "xs" },
})

type TagIcons = IconNames | "bullet"

type TagProps = {
  variant: TagVariants
  children: string
  icon?: TagIcons
}

export const Tag: FC<TagProps> = ({ variant, children, icon }) => {
  const { color, size } = tagVariantToProps[variant]

  return (
    <div className={style[variant]}>
      {icon && (
        <TagIcon
          icon={icon}
          color={color}
          size={size}
        />
      )}
      {children}
    </div>
  )
}

type TagIconProps = {
  icon: TagIcons
  color: TagColors
  size: TagSizes
}
const TagIcon: FC<TagIconProps> = ({ icon, color, size }) => {
  return (
    <div className={style[`icon-${color}`]}>
      {icon === "bullet" && <Bullet />}
      {icon !== "bullet" && (
        <Icon
          name={icon}
          size={size}
        />
      )}
    </div>
  )
}

const Bullet = () => {
  return (
    <svg
      className={"fill-current w-2 h-2"}
      viewBox="0 0 9.6 9.6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m 9.5999999,4.7999999 a 4.8000001,4.8000001 0 0 1 -4.8,4.8 A 4.8000001,4.8000001 0 0 1 5e-8,4.7999999 4.8000001,4.8000001 0 0 1 4.7999999,5e-8 4.8000001,4.8000001 0 0 1 9.5999999,4.7999999 Z" />
    </svg>
  )
}
