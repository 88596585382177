import { Button } from "@kamae-apps/shared/Components/Atoms/Button/Button"
import { BeltDescription } from "@kamae-apps/shared/Components/Molecules/BeltDescription/BeltDescription"
import { Modal } from "@kamae-apps/shared/Components/Molecules/Modal/Standard/Standard"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { useUser } from "../../Contexts/User/UserContext"
import { useBelts } from "../../hooks/useBelts"

type NewBeltModalProps = {
  close: () => void
}

const NewBeltModal: FC<NewBeltModalProps> = ({ close }) => {
  const { user } = useUser()
  const { t } = useTranslation("belts")
  const { getBeltByLevel } = useBelts()

  const belt = getBeltByLevel(user?.level ?? 0)

  return (
    <Modal
      variant={"modal-sm"}
      display={true}
      title={t("belts:modal.newBelt.title", { firstName: user?.first_name })}
      subTitle={t("belts:modal.newBelt.subTitle", { levelText: belt.name.toLowerCase() })}
      primaryButton={
        <Button
          variant={"button-primary-initial-default"}
          onClick={close}
        >
          {t("belts:modal.newBelt.proceed")}
        </Button>
      }
      close={close}
    >
      <img
        className="h-25 w-25 mb-4 m-auto"
        src="/illustration/confettis.png"
        alt="confettis"
      />
      <BeltDescription
        key={belt.variant}
        variant={belt.variant}
        levelText={belt.levelText}
        name={belt.name}
      />
    </Modal>
  )
}

export default NewBeltModal
