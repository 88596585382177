import { UserConfigKey, useUserConfig } from "@kamae-apps/shared/Hooks/userConfig/useUserConfig"
import { FC, useEffect, useState } from "react"
import { useUser } from "../../Contexts/User/UserContext"
import { WelcomeModal } from "./WelcomeModal"
import { WelcomeModalV1User } from "./WelcomeModalV1User"

export enum UserType {
  V1 = "v1",
  V2 = "v2",
}

type WelcomeModalRouterProps = {
  usertype: UserType | null
  setUsertype: (usertype: UserType | null) => void
}

export const WelcomeModalRouter: FC<WelcomeModalRouterProps> = ({ usertype, setUsertype }) => {
  const { user } = useUser()
  const { configs, setConfig } = useUserConfig()

  useEffect(() => {
    if (!configs) return

    const v1User = configs.get(UserConfigKey.FIRST_CONNECTION) && !user.avatar
    const v2User = configs.get(UserConfigKey.FIRST_CONNECTION) !== "true" && user.avatar

    if (v1User) {
      setUsertype(UserType.V1)
    } else if (v2User) {
      setUsertype(UserType.V2)
    }
  }, [configs, user, setUsertype])

  if (usertype === UserType.V1) return <WelcomeModalV1User close={() => setUsertype(null)} />
  if (usertype === UserType.V2)
    return (
      <WelcomeModal
        close={async () => {
          await setConfig(UserConfigKey.FIRST_CONNECTION, "true")
          setUsertype(null)
        }}
      />
    )
  return null
}
