import useApiStatus from "@kamae-apps/shared/Hooks/useApiStatus/useApiStatus"
import { UserConfigStateProvider } from "@kamae-apps/shared/Hooks/userConfig/Context"
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus"
import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope"
import { ToastContainer } from "@kamae-apps/shared/old/Component/Toast/Toast"
import React, { FC, MutableRefObject, Suspense } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, Route, Routes } from "react-router-dom"
import { AppLayout } from "./Components/Layout/AppLayout"
import AppLoader from "./Components/Organisms/Loaders/AppLoader/AppLoader"
import PageLoader from "./Components/Organisms/Loaders/PageLoader/PageLoader"
import { useUser } from "./Contexts/User/UserContext"
import Error404Page from "./Routes/Error404/Error404Page"
import AppLayoutGuard from "./Routes/Guards/AppLayoutGuard"
import UserGuard from "./Routes/Guards/UserGuard"
import LearningPage from "./Routes/Learning/LearningPage"
import MaintenancePage from "./Routes/Maintenance/MaintenancePage"

const TrainingsPage = React.lazy(() => import("./Routes/Trainings/TrainingsPage"))
const TeamPage = React.lazy(() => import("./Routes/Team/TeamPage"))
const LoginPage = React.lazy(() => import("./Routes/Login/LoginPage"))
const OrganizationPage = React.lazy(() => import("./Routes/Organization/OrganizationPage"))
const OrganizationUsersPage = React.lazy(() => import("./Routes/Organization/Users/UsersPage"))
const OrganizationTeamsPage = React.lazy(() => import("./Routes/Organization/Teams/TeamsPage"))
const OrganizationToolsPage = React.lazy(() => import("./Routes/Organization/Tools/ToolsPage"))
const WelcomePage = React.lazy(() => import("./Routes/Welcome/WelcomePage"))
const AvatarsPage = React.lazy(() => import("./Routes/Avatars/AvatarsPage"))
const TeamUserListPage = React.lazy(() => import("./Routes/Team/Users/UserListPage"))
const HomePage = React.lazy(() => import("./Routes/Home/HomePage"))
const PhishingCreatePage = React.lazy(() => import("./Routes/Phishing/Create/PhishingCreatePage"))
const LibraryPage = React.lazy(() => import("./Routes/Library/LibraryPage"))
const SupervisionPage = React.lazy(() => import("./Routes/Supervision/SupervisionPage"))
const AutomationPage = React.lazy(() => import("./Routes/Phishing/Automation/AutomationPage"))
const PhishingPageRouter = React.lazy(() =>
  import("./Routes/Phishing/PhishingRouter").then(module => ({ default: module.PhishingPageRouter }))
)
const PhishingDetailPageRouter = React.lazy(() =>
  import("./Routes/Phishing/PhishingRouter").then(module => ({ default: module.PhishingDetailPageRouter }))
)
const BotPage = React.lazy(() => import("./Routes/Bot/BotPage"))
const ChallengePage = React.lazy(() => import("./Routes/ChallengePage/ChallengePage"))

type AppProps = {
  loaded: boolean
  toastRef: MutableRefObject<ToastContainer>
  elearningReload: boolean
  getUser: () => Promise<void>
  doReload: () => void
  featureFlag: string[]
  displayNewBeltModal: boolean
  setDisplayNewBeltModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const RebrandedApp: FC<AppProps> = ({
  loaded,
  toastRef,
  doReload,
  elearningReload,
  featureFlag,
  getUser,
  displayNewBeltModal,
  setDisplayNewBeltModal,
}) => {
  const apiStatus = useApiStatus()
  const { t } = useTranslation("app")
  const { user } = useUser()

  const intercom = document.querySelector(".intercom-lightweight-app-launcher.intercom-launcher")

  if (intercom) {
    // Workaround before we modify the intercom component inside the app settings //
    intercom.setAttribute("style", "background: #056EF7")
  }

  return (
    <div
      data-theme={"rebranding"}
      className="bg-gradient-ocean bg-no-repeat bg-fixed overflow-hidden"
    >
      {((apiStatus.code === 503 || apiStatus.code === -1) && <MaintenancePage />) || (
        <Routes>
          <Route
            path={"/login"}
            element={
              <Suspense fallback={<AppLoader />}>
                <LoginPage />
              </Suspense>
            }
          />
          {typeof user.avatar === "undefined" && (
            <Route
              path="/welcome"
              element={
                <UserGuard loaded={loaded}>
                  <Suspense fallback={<AppLoader />}>
                    <WelcomePage />
                  </Suspense>
                </UserGuard>
              }
            />
          )}
          <Route
            element={
              <UserGuard loaded={loaded}>
                <UserConfigStateProvider loaded={loaded}>
                  <AppLayoutGuard>
                    <AppLayout
                      t={t}
                      loaded={loaded}
                      toastRef={toastRef}
                      displayNewBeltModal={displayNewBeltModal}
                      setDisplayNewBeltModal={setDisplayNewBeltModal}
                    >
                      <Outlet />
                    </AppLayout>
                  </AppLayoutGuard>
                </UserConfigStateProvider>
              </UserGuard>
            }
          >
            {scopeHas(user?.scope, Scope.COMPANY_ADMIN) && (
              <>
                <Route path={"/organization"}>
                  <Route
                    path={""}
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <OrganizationPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path={"users"}
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <OrganizationUsersPage company={user.company} />
                      </Suspense>
                    }
                  />
                  <Route
                    path={"teams"}
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <OrganizationTeamsPage
                          company={user.company}
                          getUser={getUser}
                        />
                      </Suspense>
                    }
                  />
                  <Route
                    path={"tools"}
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <OrganizationToolsPage />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path={"/supervision"}
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <SupervisionPage />
                    </Suspense>
                  }
                />
              </>
            )}
            {featureFlag.includes("team-leaders") && user.id === user.team.leader?.id && (
              <Route path={"/team"}>
                <Route
                  path={""}
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <TeamPage />
                    </Suspense>
                  }
                />
                <Route
                  path={"users"}
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <TeamUserListPage />
                    </Suspense>
                  }
                />
              </Route>
            )}
            <Route
              path={"/home"}
              element={
                <Suspense fallback={<PageLoader />}>
                  <HomePage elearningReload={elearningReload} />
                </Suspense>
              }
            />
            <Route
              path={"/elearning"}
              element={
                <Suspense fallback={<PageLoader />}>
                  <TrainingsPage
                    doReload={doReload}
                    reload={elearningReload}
                  />
                </Suspense>
              }
            />
            <Route path={"/phishing"}>
              <Route
                path={""}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <PhishingPageRouter />
                  </Suspense>
                }
              />
              <Route
                path={":id"}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <PhishingDetailPageRouter />
                  </Suspense>
                }
              />
              {scopeHas(user?.scope, Scope.COMPANY_ADMIN) && (
                <Route
                  path={"create"}
                  element={
                    <Suspense fallback={<PageLoader />}>
                      <PhishingCreatePage />
                    </Suspense>
                  }
                />
              )}
              {scopeHas(user?.scope, Scope.COMPANY_ADMIN) &&
                user.company.status !== CompanyStatus.Discovery &&
                featureFlag.includes("phishing-automated") && (
                  <Route
                    path={"automation"}
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <AutomationPage />
                      </Suspense>
                    }
                  />
                )}
            </Route>
            <Route
              path={"/reflexes"}
              element={
                <Suspense fallback={<PageLoader />}>
                  <LibraryPage />
                </Suspense>
              }
            />
            <Route
              path={"/profile"}
              element={
                <Suspense fallback={<PageLoader />}>
                  <AvatarsPage />
                </Suspense>
              }
            />
            {(featureFlag.includes("new-challenge") || user.company.status === CompanyStatus.Discovery) && (
              <Route
                path={"/challenge"}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <ChallengePage />
                  </Suspense>
                }
              />
            )}
            <Route
              path={"*"}
              element={<Error404Page />}
            />
          </Route>
          <Route
            path={"/bot"}
            element={<BotPage />}
          />
          <Route
            path={"/learning-page"}
            element={<LearningPage />}
          />
        </Routes>
      )}
    </div>
  )
}
