import React, { useEffect } from "react"
import AppLoader from "../../Components/Organisms/Loaders/AppLoader/AppLoader"
import { useUser } from "../../Contexts/User/UserContext"

type UserGuardProps = {
  loaded: boolean
  children: React.ReactNode
}

const UserGuard: React.FC<UserGuardProps> = ({ loaded, children }) => {
  const { user, loadUser } = useUser()
  useEffect(() => {
    if (loaded && !user?.scope) {
      loadUser()
    }
  }, [loadUser, loaded, user.scope])

  if (!loaded || !user?.scope) return <AppLoader />

  return <>{children}</>
}
export default UserGuard
