import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import { Auth0Provider } from "@auth0/auth0-react"
import { initializeFaro } from "@grafana/faro-web-sdk"
import { SidebarStateProvider } from "@kamae-apps/shared/Hooks/sidebar/Context"
import { ApiStatusStateProvider } from "@kamae-apps/shared/Hooks/useApiStatus/Context"
import { CryptrProvider } from "@killiangabrielkamae/cryptr-react"
import { DevSupport } from "@react-buddy/ide-toolbox"
import posthog from "posthog-js"
import { I18nextProvider } from "react-i18next"
import { AuthProvider } from "react-oidc-context"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import AppLoader from "./Components/Organisms/Loaders/AppLoader/AppLoader"
import { ElearningContext } from "./Context"
import { UserProvider } from "./Contexts/User/UserContext"
import i18n from "./i18n/i18n"
import { UserStateProvider } from "./old/Contexts/useUserContext"
import { ComponentPreviews, useInitial } from "./old/dev"
import config, { auth0Config, axaOidcConf, cryptrConf } from "./variable"

if (config.env !== "development") {
  initializeFaro({
    url: config.grafanaFaroUrl,
    app: {},
  })
}

posthog.init(config.posthogKey, {
  api_host: "https://d2bqsfxsrv9m8.cloudfront.net",
  opt_in_site_apps: true,
  autocapture: false,
  capture_pageview: true,
  capture_pageleave: false,
  disable_persistence: true, // Do not use cookies to remain GDPR compliant
  disable_session_recording: true,
  advanced_disable_feature_flags_on_first_load: true,
  // Blacklist most properties for now to remain GDPR compliant
  property_blacklist: [
    "$os",
    "$browser",
    "$device_type",
    "$current_url",
    "$host",
    "$browser_version",
    "$browser_language",
    "$lib",
    "$lib_version",
    "$referrer",
    "$referring_domain",
    "$device_id",
    "$session_id",
    "$recording_id",
    "$window_id",
    "$anon_distinct_id",
  ],
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ElearningContext.Provider value={new Map()}>
        <ApiStatusStateProvider>
          <AuthProvider {...axaOidcConf}>
            <CryptrProvider {...cryptrConf}>
              <Auth0Provider
                domain={auth0Config.loginDomain}
                clientId={auth0Config.clientId}
                redirectUri={config.app}
                audience={config.api}
                scope={"read:all"}
              >
                <BrowserRouter>
                  <UserProvider>
                    <UserStateProvider>
                      <SidebarStateProvider>
                        <DevSupport
                          ComponentPreviews={ComponentPreviews}
                          useInitialHook={useInitial}
                        >
                          <Suspense fallback={<AppLoader />}>
                            <App />
                          </Suspense>
                        </DevSupport>
                      </SidebarStateProvider>
                    </UserStateProvider>
                  </UserProvider>
                </BrowserRouter>
              </Auth0Provider>
            </CryptrProvider>
          </AuthProvider>
        </ApiStatusStateProvider>
      </ElearningContext.Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("kamaeRoot")
)

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js")
    .then(serviceWorker => {
      // eslint-disable-next-line no-console
      console.log("Service Worker registered:", serviceWorker)
    })
    .catch(error => {
      console.error("Error while registering service worker: ", error)
    })
}
