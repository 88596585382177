import { Spinner } from "@kamae-apps/shared/Components/Atoms/Spinner/Spinner"
import style from "./PageLoader.module.css"

const PageLoader = () => {
  return (
    <div className={style["loader-container"]}>
      <Spinner />
    </div>
  )
}

export default PageLoader
