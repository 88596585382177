import { ToastContainerContext } from "@kamae-apps/shared/old/Component/Toast/Context"
import { ToastContainer } from "@kamae-apps/shared/old/Component/Toast/Toast"
import { TFunction } from "i18next"
import { FC, MutableRefObject, ReactNode } from "react"
import { useMatch } from "react-router-dom"
import { useFeatureFlags } from "../../old/hooks/posthog/useFeatureFlags"
import { MessagePortal } from "../MessagePortal/MessagePortal"
import { Menu } from "../Organisms/Menu/Menu"
import { PageLayout } from "./PageLayout"
import { PageLayoutVariant } from "./PageLayout"

type AppLayoutProps = {
  t: TFunction
  loaded: boolean
  toastRef: MutableRefObject<ToastContainer>
  children: ReactNode
  displayNewBeltModal: boolean
  setDisplayNewBeltModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppLayout: FC<AppLayoutProps> = ({
  t,
  loaded,
  toastRef,
  children,
  displayNewBeltModal,
  setDisplayNewBeltModal,
}) => {
  const isNewsBarActive = useFeatureFlags().includes("display-news-bar")
  const isPhishingCampaignDetail = useMatch("/phishing/:id")

  return (
    <>
      <MessagePortal
        isNewsBarActive={isNewsBarActive}
        displayNewBeltModal={displayNewBeltModal}
        setDisplayNewBeltModal={setDisplayNewBeltModal}
      />
      <div className={"flex min-h-screen max-w-495 m-auto"}>
        <Menu
          t={t}
          loaded={loaded}
          isNewsBarActive={isNewsBarActive}
        />
        <ToastContainerContext.Provider value={toastRef}>
          <div className={"grow overflow-y-auto overflow-x-hidden scroll-smooth custom-scrollbar"}>
            <PageLayout
              variant={isPhishingCampaignDetail ? PageLayoutVariant.WHITE : PageLayoutVariant.GLASS}
              isNewsBarActive={isNewsBarActive}
            >
              {loaded && children}
              <ToastContainer ref={toastRef} />
            </PageLayout>
          </div>
        </ToastContainerContext.Provider>
      </div>
    </>
  )
}
