import { Button } from "@kamae-apps/shared/Components/Atoms/Button/Button"
import { Tatami } from "@kamae-apps/shared/Components/Atoms/Tatami/Tatami"
import { Trans } from "react-i18next"
import { IntercomLauncher } from "../../Components/IntercomLauncher/IntercomLauncher"
import { useMaintenancePage } from "./useMaintenancePage"

export default function MaintenancePage() {
  const { t } = useMaintenancePage()
  return (
    <div className={"fixed z-30 top-0 left-0 w-full h-full bg-gradient-ocean p-4"}>
      <div
        className={
          "relative glasseffect-xl bg-neutral-white/50 rounded-xl h-full w-full flex justify-center items-center overflow-hidden"
        }
      >
        <Tatami className={"invisible md:visible absolute bottom-14 text-[#DBDBDB] no-fill w-11/12"} />
        <div className={"w-2/3 xl:w-1/2 flex flex-col lg:flex-row justify-between items-center gap-20 z-10"}>
          <div className={"flex flex-col gap-5"}>
            <h1 className={"display-md-bold text-neutral-gray-900"}>{t("maintenance:rebranding.title")}</h1>
            <p className={"body-md-regular text-neutral-gray-600"}>
              <Trans
                t={t}
                ns={"maintenance"}
                i18nKey={"rebranding.subtitle"}
              />
            </p>
            <IntercomLauncher>
              <Button variant={"button-secondary-light-small"}>{t("maintenance:rebranding.action")}</Button>
            </IntercomLauncher>
          </div>
          <img
            className={"w-80"}
            src={"avatarMaintenance.png"}
            alt={"Error avatar"}
          />
        </div>
      </div>
    </div>
  )
}
