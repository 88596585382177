import { UserConfigKey, useUserConfig } from "@kamae-apps/shared/Hooks/userConfig/useUserConfig"
import { FC, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useUser } from "../../Contexts/User/UserContext"

const AppLayoutGuard: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { user } = useUser()
  const navigate = useNavigate()
  const { configs } = useUserConfig()

  useEffect(() => {
    if (configs && user && configs.get(UserConfigKey.FIRST_CONNECTION) !== "true" && !user.avatar) {
      navigate("/welcome")
    }
  }, [configs, user, navigate])
  return children
}

export default AppLayoutGuard
