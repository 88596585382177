import { Spinner } from "@kamae-apps/shared/Components/Atoms/Spinner/Spinner"
import style from "./AppLoader.module.css"

const AppLoader = () => {
  return (
    <div
      data-theme={"rebranding"}
      className={"bg-gradient-ocean h-screen w-screen flex justify-center items-center p-4 z-30"}
    >
      <div className={style["loading-container"]}>
        <img
          src={"/illustration/medal.png"}
          alt={"Medal"}
          className={style["loading-top-left-img"]}
        />
        <img
          src={"/illustration/computer_kamae_logo_bi.png"}
          alt={"Computer"}
          className={style["loading-top-right-img"]}
        />
        <img
          src={"/illustration/phishing_envelope.png"}
          alt={"Phishing"}
          className={style["loading-bottom-img"]}
        />
        <div className={style["loading-content"]}>
          <img
            className={"w-36"}
            src={"kamaeLogo.svg"}
            alt={"Kamaé"}
          />
          <Spinner />
        </div>
      </div>
    </div>
  )
}

export default AppLoader
