import { Button } from "@kamae-apps/shared/Components/Atoms/Button/Button"
import { DisplayModal } from "@kamae-apps/shared/Components/Molecules/Modal/Display/Display"
import useMediaQuery from "@kamae-apps/shared/Hooks/useMediaQuery"
import { useStep } from "@kamae-apps/shared/Hooks/useStep"
import TUser from "@kamae-apps/shared/Types/TUser"
import { TFunction } from "i18next"
import type { FC, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { useUser } from "../../Contexts/User/UserContext"

type WelcomeModalProps = {
  close: () => void
}

type StepData = {
  title: string | ReactNode
  subTitle: string
  nextButton: string
  previousButton?: string
  imageSrc: string
  imageAlt: string
  imageClassName?: string
}

export const WelcomeModal: FC<WelcomeModalProps> = ({ close }) => {
  const { user } = useUser()
  const { t } = useTranslation("app")
  const { isMobile } = useMediaQuery()

  const { currentStepData, onNext, onPrevious } = useStep<StepData>({
    steps: getStepData(t, user, isMobile),
    onComplete: close,
  })

  return (
    <Step
      stepData={currentStepData}
      onNext={onNext}
      onPrevious={onPrevious}
    />
  )
}

const getStepData = (t: TFunction, user: TUser, isMobile: boolean): StepData[] => [
  {
    title: (
      <>
        <span className="block">{t("app:welcomeModal.step1.title", { name: user.first_name })}</span>
        <span className="block">{t("app:welcomeModal.step1.title2")}</span>
      </>
    ),
    subTitle: t("app:welcomeModal.step1.subTitle"),
    nextButton: t("app:welcomeModal.step1.nextButton"),
    imageSrc: `/tatami_kamaesan${isMobile ? "_mobile" : ""}.png`,
    imageAlt: "tatami_kamaesan",
    imageClassName: `${isMobile ? "w-128" : "w-133"} h-[329px] m-auto`,
  },
  {
    title: t("app:welcomeModal.step2.title"),
    subTitle: t("app:welcomeModal.step2.subTitle"),
    nextButton: t("app:welcomeModal.step2.nextButton"),
    previousButton: t("app:welcomeModal.step2.previousButton"),
    imageSrc: `/animation/navigation${isMobile ? "_mobile" : ""}.gif`,
    imageAlt: "navigation",
  },
  {
    title: t("app:welcomeModal.step3.title"),
    subTitle: t("app:welcomeModal.step3.subTitle"),
    nextButton: t("app:welcomeModal.step3.nextButton"),
    previousButton: t("app:welcomeModal.step3.previousButton"),
    imageSrc: `/animation/training${isMobile ? "_mobile" : ""}.gif`,
    imageAlt: "training",
  },
  {
    title: t("app:welcomeModal.step4.title"),
    subTitle: t("app:welcomeModal.step4.subTitle"),
    nextButton: t("app:welcomeModal.step4.nextButton"),
    previousButton: t("app:welcomeModal.step4.previousButton"),
    imageSrc: `/animation/progression${isMobile ? "_mobile" : ""}.gif`,
    imageAlt: "progression",
  },
]

type StepProps = {
  stepData: StepData
  onNext: () => void
  onPrevious?: () => void
}

const Step: FC<StepProps> = ({ stepData, onNext, onPrevious }) => {
  const { isMobile } = useMediaQuery()
  const { title, subTitle, nextButton, previousButton, imageSrc, imageAlt, imageClassName } = stepData

  return (
    <DisplayModal
      display
      title={title}
      subTitle={subTitle}
      primaryButton={
        <Button
          variant="button-primary-initial-default"
          onClick={onNext}
        >
          {nextButton}
        </Button>
      }
      secondaryButton={
        onPrevious && (
          <Button
            variant="button-secondary-initial-default"
            onClick={onPrevious}
          >
            {previousButton}
          </Button>
        )
      }
    >
      <img
        className={
          imageClassName
            ? imageClassName
            : `${isMobile ? "h-96 w-132" : "h-75 w-133"} m-auto border border-neutral-gray-300 rounded-xl`
        }
        src={imageSrc}
        alt={imageAlt}
      />
    </DisplayModal>
  )
}
