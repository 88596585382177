import { DesignBelts } from "@kamae-apps/shared/Components/DesignStates/DesignBelts"
import { iconsDictionary } from "@kamae-apps/shared/Components/Fondations/Icons/iconsDictionary"
import clsx from "clsx"
import { FC } from "react"
import style from "./Belt.module.css"

type BeltSizes = "s" | "m"

const beltSizeToClass = Object.freeze({
  s: "w-6 h-6",
  m: "w-10 h-10",
})

type BeltProps = {
  beltColor: DesignBelts
  size: BeltSizes
}

export const Belt: FC<BeltProps> = ({ beltColor, size }) => {
  const beltPath = iconsDictionary.kamaeBelt
  const sizeClass = beltSizeToClass[size]

  return (
    <div className={style[`belt-${beltColor}`]}>
      <svg
        className={clsx("fill-current", sizeClass)}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        {beltPath}
      </svg>
    </div>
  )
}
