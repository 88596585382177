import clsx from "clsx"
import { FC, ReactNode } from "react"

type PageLayoutProps = {
  children: ReactNode
  variant: PageLayoutVariant
  isNewsBarActive: boolean
}

export enum PageLayoutVariant {
  GLASS = "glass",
  WHITE = "white",
}

export const PageLayout: FC<PageLayoutProps> = ({ children, variant, isNewsBarActive }) => {
  return (
    <main
      className={clsx(
        "min-h-screen flex px-4 py-8 mb-21 rebranding-sm:py-3 rebranding-sm:pr-3 rebranding-sm:pl-0 rebranding-sm:mb-0 rebranding-sm:ml-21 lg:ml-60",
        variant === PageLayoutVariant.WHITE && "bg-neutral-white rebranding-sm:bg-transparent",
        isNewsBarActive && "mt-24 rebranding-sm:mt-23 lg:mt-16 lg:min-h-[calc(100vh-64px)]"
      )}
    >
      <div
        className={clsx(
          "flex-1 flex flex-col rebranding-sm:px-6 rebranding-sm:py-6 rebranding-sm:glass-effect-xl rebranding-sm:rounded-xl",
          variant === PageLayoutVariant.WHITE && "bg-neutral-white",
          variant === PageLayoutVariant.GLASS && "rebranding-sm:bg-neutral-white/50"
        )}
      >
        {children}
      </div>
    </main>
  )
}
