import clsx from "clsx"
import { FC } from "react"
import style from "./RoundAvatar.module.css"

type RoundAvatarSize = "s" | "m" | "l"

const sizeToClass = Object.freeze({
  s: "w-6 h-6",
  m: "w-7 h-7",
  l: "w-9 h-9",
})

type RoundAvatarProps = {
  imgSrc: string
  imgAlt: string
  size: RoundAvatarSize
  displayShadow?: boolean
  cropImage?: boolean
}

export const RoundAvatar: FC<RoundAvatarProps> = ({ imgSrc, imgAlt, size, displayShadow, cropImage }) => {
  return (
    <div className={clsx(style["round-avatar"], sizeToClass[size], displayShadow && style["round-avatar-shadow"])}>
      <img
        className={clsx(cropImage && style["round-avatar-image"])}
        src={imgSrc}
        alt={imgAlt}
      />
    </div>
  )
}
