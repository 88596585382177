import { FC } from "react"

type TatamiProps = {
  className?: string
}

export const Tatami: FC<TatamiProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 1166 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1001.15 95.0984L1164 206H1018.75M1001.15 95.0984H897.708M1001.15 95.0984L931.225 46.0328M164.847 95.0984L298.956 1H369.967M164.847 95.0984L82.9298 150.885M164.847 95.0984H269.543M583 1H440.978M583 1H654.011M583 1V46.0328M583 206V150.885M583 206H873.5M583 206H437.75M82.9298 150.885L2 206H292.5M82.9298 150.885H332.967M583 150.885H833.276M583 150.885V46.0328M873.5 206H1018.75M873.5 206L757.639 46.0328M292.5 206H437.75M292.5 206L408.361 46.0328M440.978 1H369.967M440.978 1L408.361 46.0328M725.022 1H867.044L931.225 46.0328M725.022 1H654.011M725.022 1L757.639 46.0328M687.54 95.0984L654.011 1M687.54 95.0984H477.373M687.54 95.0984H897.708M269.543 95.0984L369.967 1M269.543 95.0984H477.373M477.373 95.0984L437.75 206M897.708 95.0984L1018.75 206M408.361 46.0328H583M757.639 46.0328H931.225"
        stroke="currentColor"
      />
    </svg>
  )
}
