import { useState } from "react"

type UseStepOptions<T> = {
  steps: T[]
  onComplete: () => void
}
type UseStepReturn<T> = {
  currentStepData: T
  onNext: () => void
  onPrevious: (() => void) | undefined
}

export const useStep = <T>({ steps, onComplete }: UseStepOptions<T>): UseStepReturn<T> => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)

  const totalSteps = steps.length
  const isFirstStep = currentStepIndex === 0
  const isLastStep = currentStepIndex === totalSteps - 1
  const currentStepData = steps[currentStepIndex]

  const goToPreviousStep = () => {
    if (!isFirstStep) {
      setCurrentStepIndex(currentStepIndex - 1)
    }
  }

  const onNext = () => {
    if (isLastStep) {
      onComplete()
    } else {
      setCurrentStepIndex(currentStepIndex + 1)
    }
  }

  const onPrevious = isFirstStep ? undefined : goToPreviousStep

  return {
    currentStepData,
    onNext,
    onPrevious,
  }
}
