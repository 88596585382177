import { Tooltip } from "@kamae-apps/shared/Components/Atoms/Tooltip/Tooltip"
import clsx from "clsx"
import { FC } from "react"
import Icon from "../../../../Fondations/Icons/Icon"
import styles from "../../Dropdown.module.css"
import { type DropdownMenuContent } from "../Menu"

type DropdownMenuProps = {
  onCloseMenu: () => void
  content: DropdownMenuContent
}

export const MenuButton: FC<DropdownMenuProps> = ({ onCloseMenu, content }) => {
  const iconContent = content.iconName && (
    <div
      className={clsx(
        "text-neutral-gray-500",
        content.isDanger && !content.isDisabled && "group-hover:text-primary-error-500",

        content.isDisabled && styles.disabled
      )}
    >
      <Icon name={content.iconName} />
    </div>
  )

  const textContent = <p className="body-sm-regular whitespace-nowrap truncate">{content.displayNameKey}</p>

  if (content.isDisabled && content.disabledTooltip) {
    return (
      <div
        className={clsx(
          styles.item,
          "gap-3 text-neutral-gray-900 hover:bg-neutral-gray-50",
          content.isDisabled && styles.disabled
        )}
        onClick={e => e.stopPropagation()}
      >
        <Tooltip
          tooltipPosition={content.disabledTooltipPosition ?? "tooltip-top"}
          title={content.disabledTooltip ?? ""}
        >
          <div className="flex gap-3 items-center">
            {iconContent}
            {textContent}
          </div>
        </Tooltip>
      </div>
    )
  }

  return (
    <button
      key={content.displayNameKey}
      className={clsx(
        styles.item,
        "gap-3 text-neutral-gray-900 hover:bg-neutral-gray-50",
        content.isDisabled && styles.disabled,
        content.isDanger && !content.isDisabled && `group ${styles.danger}`
      )}
      disabled={content.isDisabled}
      onClick={e => {
        content?.onClick(e)
        onCloseMenu()
      }}
      type="button"
    >
      {iconContent}
      {textContent}
    </button>
  )
}
