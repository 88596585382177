import { IconNames } from "@kamae-apps/shared/Components/Fondations/Icons/IconNames.type"
import useBreakpoints from "@kamae-apps/shared/Hooks/useMediaQuery"
import { useMemo } from "react"

export type MenuEntry = {
  path: string
  iconName: IconNames
  displayNameKey: string
  isAdminRequired: boolean
  isTeamLeaderRequired: boolean
}

export const useMenuEntry = (isAdmin: boolean, isTeamLeader: boolean) => {
  const { isMobile } = useBreakpoints()

  const allMenuEntries = useMemo<MenuEntry[]>(
    () => [
      {
        path: "/organization",
        iconName: "dashboard",
        displayNameKey: "app:sidebar.organization.label",
        isAdminRequired: true,
        isTeamLeaderRequired: false,
      },
      {
        path: "/supervision",
        iconName: "supervision",
        displayNameKey: "app:sidebar.supervision.label",
        isAdminRequired: true,
        isTeamLeaderRequired: false,
      },
      {
        path: "/team",
        iconName: "manGroup",
        displayNameKey: "app:sidebar.team.label",
        isAdminRequired: false,
        isTeamLeaderRequired: true,
      },
      {
        path: "/home",
        iconName: "tatami",
        displayNameKey: "app:sidebar.home.label",
        isAdminRequired: false,
        isTeamLeaderRequired: false,
      },
      {
        path: "/elearning",
        iconName: "kamaeBeltOutlined",
        displayNameKey: "app:sidebar.elearning.label",
        isAdminRequired: false,
        isTeamLeaderRequired: false,
      },
      {
        path: "/phishing",
        iconName: "phishing",
        displayNameKey: "app:sidebar.phishing.label",
        isAdminRequired: false,
        isTeamLeaderRequired: false,
      },
      {
        path: "/reflexes",
        iconName: "book",
        displayNameKey: "app:sidebar.reflexe.label",
        isAdminRequired: false,
        isTeamLeaderRequired: false,
      },
    ],
    []
  )

  const menuEntries = useMemo(() => {
    return isMobile
      ? allMenuEntries.filter(entry => !["/team", "/organization", "/supervision"].includes(entry.path))
      : allMenuEntries
  }, [isMobile, allMenuEntries])

  const filteredMenuEntries = useMemo(() => {
    return menuEntries.filter(
      menuEntry =>
        (menuEntry.isAdminRequired && isAdmin) ||
        (menuEntry.isTeamLeaderRequired && isTeamLeader) ||
        (!menuEntry.isAdminRequired && !menuEntry.isTeamLeaderRequired)
    )
  }, [menuEntries, isAdmin, isTeamLeader])

  return { filteredMenuEntries }
}
