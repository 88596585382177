import { IconNames } from "@kamae-apps/shared/Components/Fondations/Icons/IconNames.type"
import type { TFunction } from "i18next"
import { FC } from "react"
import { matchPath, useLocation } from "react-router-dom"
import { MenuEntry } from "./MenuEntry"
import { useMenuEntry } from "./useMenuEntry"

type MenuEntryListProps = {
  t: TFunction
  loaded: boolean
  isAdmin: boolean
  isTeamLeader: boolean
}

export const MenuEntryList: FC<MenuEntryListProps> = ({ t, isAdmin, isTeamLeader }: MenuEntryListProps) => {
  const location = useLocation()
  const { filteredMenuEntries } = useMenuEntry(isAdmin, isTeamLeader)

  return filteredMenuEntries.map(entry => {
    const isSubPathOfExistingRoute = matchPath({ path: `${entry.path}/*` }, location.pathname) !== null
    return (
      <MenuEntry
        key={entry.path}
        content={t(entry.displayNameKey)}
        iconName={entry.iconName}
        goTo={entry.path}
        isActive={location.pathname === entry.path || isSubPathOfExistingRoute}
        isDisabled={false}
      />
    )
  })
}
