import { TagBeltColors } from "@kamae-apps/shared/Components/Atoms/Tag/Tag"

export enum DesignBelts {
  white = "white",
  yellow = "yellow",
  orange = "orange",
  green = "green",
  blue = "blue",
  purple = "purple",
  brown = "brown",
  black = "black",
  red = "red",
  bronze = "bronze",
  silver = "silver",
  gold = "gold",
}

const belts = [
  DesignBelts.white,
  DesignBelts.yellow,
  DesignBelts.orange,
  DesignBelts.green,
  DesignBelts.blue,
  DesignBelts.purple,
  DesignBelts.brown,
  DesignBelts.black,
  DesignBelts.red,
  DesignBelts.bronze,
  DesignBelts.silver,
  DesignBelts.gold,
]

const beltLevelPointsMap = new Map<DesignBelts, number>([
  [DesignBelts.white, 5],
  [DesignBelts.yellow, 11],
  [DesignBelts.orange, 16],
  [DesignBelts.green, 20],
  [DesignBelts.blue, 24],
  [DesignBelts.purple, 27],
  [DesignBelts.brown, 31],
  [DesignBelts.black, 34],
  [DesignBelts.red, 37],
  [DesignBelts.bronze, 39],
  [DesignBelts.silver, 42],
  [DesignBelts.gold, 44],
])

export const getBeltColor = (level: number) => {
  if (level >= belts.length) {
    return belts[belts.length - 1]
  }
  return belts[level]
}

export const getBeltLevel = (belt: DesignBelts) => {
  return belts.indexOf(belt) + 1
}

export const getNextLevelPoints = (belt: DesignBelts) => {
  return beltLevelPointsMap.get(belt) ?? 5
}

export const getTagColorFromBeltColor = (belt: DesignBelts): TagBeltColors => {
  switch (belt) {
    case DesignBelts.white:
      return "belt-white"
    case DesignBelts.orange:
      return "belt-orange"
    case DesignBelts.yellow:
      return "belt-yellow"
    case DesignBelts.green:
      return "belt-green"
    case DesignBelts.blue:
      return "belt-blue"
    case DesignBelts.purple:
      return "belt-purple"
    case DesignBelts.brown:
      return "belt-brown"
    case DesignBelts.black:
      return "belt-black"
    case DesignBelts.red:
      return "belt-red"
    case DesignBelts.bronze:
      return "belt-bronze"
    case DesignBelts.silver:
      return "belt-silver"
    case DesignBelts.gold:
      return "belt-gold"
  }
}
