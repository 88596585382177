import { Tag } from "@kamae-apps/shared/Components/Atoms/Tag/Tag"
import Icon from "@kamae-apps/shared/Components/Fondations/Icons/Icon"
import { MenuEntryList } from "@kamae-apps/shared/Components/Molecules/Menu/MenuEntryList"
import { UserMenu } from "@kamae-apps/shared/Components/Molecules/UserMenu/UserMenu"
import clsx from "clsx"
import type { TFunction } from "i18next"
import { FC } from "react"
import { StarterModal } from "../../Molecules/StarterModal/StarterAdModal"
import styles from "./Menu.module.css"
import { useMenu } from "./useMenu"

interface MenuProps {
  t: TFunction
  loaded: boolean
  isNewsBarActive: boolean
}

export const Menu: FC<MenuProps> = ({ t, loaded, isNewsBarActive }: MenuProps) => {
  const {
    user,
    isAdmin,
    isTeamLeader,
    userMenuEntries,
    getAvatarUrl,
    getRoundedAvatarUrl,
    isStarterCompany,
    starterModal,
    setStarterModal,
  } = useMenu(t)

  return (
    <>
      <div
        className={clsx(
          styles["menu-base"],
          styles["menu-sm"],
          styles["menu-lg"],
          isNewsBarActive && styles["news-bar"]
        )}
      >
        <div className="hidden rebranding-sm:block lg:hidden">
          <Icon
            name="kamaeBelt"
            size="m"
          />
        </div>
        <img
          className="hidden lg:block h-[34px] w-full"
          src="/kamaeLogo.svg"
          alt="kamae-logo"
        />
        {isStarterCompany && isAdmin && (
          <button
            className="hidden lg:block border-secondary-soft-purple-300 border-1 rounded-lg px-1"
            type={"button"}
            onClick={() => setStarterModal(true)}
          >
            <Tag variant={"tag-default-soft-purple"}>{t("discoveryLicence:rebranding.licenceName")}</Tag>
          </button>
        )}
        <div className="hidden lg:block relative w-[172px] h-[176px]">
          <img
            className="absolute bottom-0 left-0 right-0 h-[140px] w-[172px]"
            src="/avatar-background.png"
            alt="avatar-background"
          />
          <img
            className="absolute bottom-0 left-0 right-0 mx-auto h-[176px] w-auto"
            src={getAvatarUrl(user.avatar)}
            alt="kamae-san"
          />
        </div>
        <div className="flex grow justify-between items-center rebranding-sm:flex-col rebranding-sm:w-full">
          <MenuEntryList
            t={t}
            loaded={loaded}
            isAdmin={isAdmin}
            isTeamLeader={isTeamLeader}
          />
          <div className="mt-auto lg:w-full">
            <UserMenu
              user={user}
              entries={userMenuEntries}
              getRoundedAvatarUrl={getRoundedAvatarUrl}
            />
          </div>
        </div>
      </div>
      {starterModal && (
        <StarterModal
          isDisplayed={starterModal}
          close={() => setStarterModal(false)}
        />
      )}
    </>
  )
}
