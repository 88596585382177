import { Belt } from "@kamae-apps/shared/Components/Atoms/Belt/Belt"
import { DesignBelts } from "@kamae-apps/shared/Components/DesignStates/DesignBelts"
import clsx from "clsx"
import { FC } from "react"
import style from "./BeltDescription.module.css"

export enum BeltVariants {
  WHITE = "belt-white",
  ORANGE = "belt-orange",
  YELLOW = "belt-yellow",
  GREEN = "belt-green",
  BLUE = "belt-blue",
  PURPLE = "belt-purple",
  BROWN = "belt-brown",
  BLACK = "belt-black",
  RED = "belt-red",
  BRONZE = "belt-bronze",
  SILVER = "belt-silver",
  GOLD = "belt-gold",
}

const beltVariantToDesignBelt: Record<BeltVariants, DesignBelts> = Object.freeze({
  [BeltVariants.WHITE]: DesignBelts.white,
  [BeltVariants.ORANGE]: DesignBelts.orange,
  [BeltVariants.YELLOW]: DesignBelts.yellow,
  [BeltVariants.GREEN]: DesignBelts.green,
  [BeltVariants.BLUE]: DesignBelts.blue,
  [BeltVariants.PURPLE]: DesignBelts.purple,
  [BeltVariants.BROWN]: DesignBelts.brown,
  [BeltVariants.BLACK]: DesignBelts.black,
  [BeltVariants.RED]: DesignBelts.red,
  [BeltVariants.BRONZE]: DesignBelts.bronze,
  [BeltVariants.SILVER]: DesignBelts.silver,
  [BeltVariants.GOLD]: DesignBelts.gold,
})

export type BeltDescriptionProps = {
  variant: BeltVariants
  levelText: string
  name: string
}

export const BeltDescription: FC<BeltDescriptionProps> = ({ variant, levelText, name }) => {
  const belt = beltVariantToDesignBelt[variant]

  return (
    <div className={clsx(style["belt-container"], style[variant])}>
      <div>
        <p className={style["belt-level"]}>{levelText}</p>
        <p className={style["belt-name"]}>{name}</p>
      </div>
      <Belt
        beltColor={belt}
        size={"m"}
      />
    </div>
  )
}
