import clsx from "clsx"
import { FC, ReactNode } from "react"
import style from "./Card.module.css"

type CardProps = {
  className?: string
  children: ReactNode
  onClick?: () => void
}

export const Card: FC<CardProps> = ({ className, children, onClick }) => {
  return (
    <div
      className={clsx(style["card-base"], className)}
      onClick={onClick}
      data-clickable={onClick !== undefined}
    >
      {children}
    </div>
  )
}
