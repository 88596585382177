import { BREAKPOINTS } from "@kamae-apps/shared/Style/conf.json"
import { useEffect, useState } from "react"

function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    const listener = () => setMatches(media.matches)
    listener()
    media.addEventListener("change", listener)
    return () => media.removeEventListener("change", listener)
  }, [query])

  return matches
}

export default function useBreakpoints() {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.MOBILE}px)`)
  const isPortraitTablet = useMediaQuery(
    `(min-width: ${BREAKPOINTS.MOBILE + 1}px) and (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px)`
  )
  const isLandscapeTablet = useMediaQuery(
    `(min-width: ${BREAKPOINTS.TABLET_PORTRAIT + 1}px) and (max-width: ${BREAKPOINTS.TABLET_LANDSCAPE}px)`
  )
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.DESKTOP}px)`)
  return { isMobile, isPortraitTablet, isLandscapeTablet, isDesktop }
}
