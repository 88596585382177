import { FC, ReactNode } from "react"
import styles from "./NewsBar.module.css"

type NewsBarProps = {
  title: string
  primaryButton?: ReactNode
  secondaryButton?: ReactNode
}

export const NewsBar: FC<NewsBarProps> = ({ title, primaryButton, secondaryButton }) => {
  return (
    <div className={styles["newsbar-container"]}>
      <p className={styles["newsbar-title"]}>{title}</p>
      <div className={styles["newsbar-buttons-container"]}>
        {secondaryButton}
        {primaryButton}
      </div>
    </div>
  )
}
