import { IconNames, IconSizes } from "@kamae-apps/shared/Components/Fondations/Icons/IconNames.type"
import clsx from "clsx"
import { FC } from "react"
import { iconsDictionary } from "./iconsDictionary"

type IconProps = {
  name: IconNames
  size?: IconSizes
}

const sizeToTailwindClass: Record<IconSizes, string> = Object.freeze({
  xs: "w-4 h-4",
  s: "w-5 h-5",
  m: "w-6 h-6",
  l: "w-8 h-8",
})

const Icon: FC<IconProps> = ({ name, size = "s" }) => {
  const path = iconsDictionary[name]
  const sizeClass = sizeToTailwindClass[size]

  return (
    <svg
      className={clsx("fill-current", sizeClass)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {path}
    </svg>
  )
}
export default Icon
