import { useAuth0 } from "@auth0/auth0-react"
import useApiStatus from "@kamae-apps/shared/Hooks/useApiStatus/useApiStatus"
import useReload from "@kamae-apps/shared/Hooks/useReload"
import type { APIError } from "@kamae-apps/shared/Types/API"
import { DefaultLanguage } from "@kamae-apps/shared/Types/Company/DefaultLanguage"
import type { TCompany } from "@kamae-apps/shared/Types/Company/TCompany"
import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope"
import type TUser from "@kamae-apps/shared/Types/TUser"
import { ToastContainer } from "@kamae-apps/shared/old/Component/Toast/Toast"
import { apiRequest, basicCompare, isLargeScreen } from "@kamae-apps/shared/utils"
import { useCryptr } from "@killiangabrielkamae/cryptr-react"
import posthog from "posthog-js"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useFeatureFlags } from "./old/hooks/posthog/useFeatureFlags"
import useUser from "./old/hooks/useUser"
import { fromCodeToLang, getCompanyPlan, sha1, shouldThePathBeMemoized } from "./utils"

import { faro } from "@grafana/faro-web-sdk"
import { useWindowSize } from "@kamae-apps/shared/Hooks/useWindowSize"
import { useAuth } from "react-oidc-context"
import { RebrandedApp } from "./RebrandedApp"
import useIntercom from "./old/hooks/useIntercom"
import config from "./variable"

export default function App() {
  const size = useWindowSize()
  const [jwt, setJwt] = useState<string | null>(null)

  const [loaded, setLoaded] = useState<boolean>(false)
  const { reload: elearningReload, doReload: doElearningReload } = useReload()
  const [displayNewBelt, setDisplayNewBelt] = useState(false)

  const ref = useRef<ToastContainer>(null)
  const { user, setUserState } = useUser()
  const [searchParams] = useSearchParams()

  const featureFlag = useFeatureFlags()

  const cryptr = useCryptr()
  const auth0 = useAuth0()
  const oidcAxa = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const rebrandingDisplay = true

  const { i18n } = useTranslation("app")

  const persistLanguage = useCallback(
    (lng?: string) => {
      if (!i18n.isInitialized || !lng) {
        return
      }

      apiRequest("/user/lang", {
        method: "POST",
        body: {
          code: lng,
        },
        headers: {
          "X-Targeted-Language": lng,
        },
      })
    },
    [i18n.isInitialized]
  )

  // Need to be in a useEffect, if not, it will re-subscribe to languageChanged event on each render
  useEffect(() => {
    i18n.on("languageChanged", persistLanguage)

    return () => {
      i18n.off("languageChanged", persistLanguage)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [persistLanguage, i18n])

  const { instantiateIntercom, updateIntercomWithUserInformation, shutdownIntercom } = useIntercom()

  useEffect(() => {
    instantiateIntercom()
  }, [instantiateIntercom])

  useEffect(() => {
    if (loaded && typeof user.scope !== "undefined") {
      if (scopeHas(user.scope, Scope.COMPANY_ADMIN)) {
        updateIntercomWithUserInformation(!isLargeScreen(size))
      } else {
        shutdownIntercom()
      }
    }
  }, [shutdownIntercom, updateIntercomWithUserInformation, loaded, user.scope, size])

  const getUser = useCallback(async () => {
    if (!jwt) {
      return
    }
    try {
      const data = await apiRequest<TUser>(`/user?v2=${rebrandingDisplay}`)

      if (!data) {
        return
      }

      localStorage.setItem("lang", fromCodeToLang(data.languageId))
      const oldBelt = localStorage.getItem("belt")

      if (oldBelt !== null) {
        if (data.level > Number.parseInt(oldBelt)) {
          setDisplayNewBelt(true)
          const level = data.level
          sha1(data.email).then(identifier => {
            posthog.identify(identifier)
            posthog.capture("earned new belt", {
              level: level,
            })
          })
        }
      }

      localStorage.setItem("belt", data.level.toString())

      if (user === null || !basicCompare(data, user)) {
        setUserState(data)
      }
    } catch (err) {
      const errorsToCatch = [401, 403]
      if (errorsToCatch.includes((err as APIError).code)) {
        const redirectTo = () => {
          switch ((err as APIError).code) {
            case 401:
              return rebrandingDisplay ? "/login?error=unauthorized" : "/unauthorized"
            case 403:
              return "/forbidden"
            default:
              return "/"
          }
        }

        if (cryptr.isAuthenticated()) {
          // We need to use window.location.assign in order to refresh Cryptr SDK values
          await cryptr.logOut(() => window.location.assign(config.app + redirectTo()), undefined, false)
        } else if (auth0.isAuthenticated) {
          auth0.logout({ returnTo: window.location.origin + redirectTo() })
        }
        localStorage.clear()
      }
    }
  }, [jwt, user, setUserState, auth0, cryptr])

  const RequestAccessTokenCryptr = useCallback(async () => {
    const token = cryptr.getCurrentAccessToken()
    setJwt(token ?? "")
    localStorage.setItem("jwt", token ?? "")
    await getUser()
  }, [cryptr, getUser])

  const RequestAccessTokenAuth0 = useCallback(async () => {
    const token = await auth0.getAccessTokenSilently()
    setJwt(token)
    localStorage.setItem("jwt", token)
    await getUser()
  }, [auth0, getUser])

  const requestIDTokenOidcAXA = useCallback(async () => {
    const idToken = oidcAxa.user.id_token
    setJwt(idToken)
    localStorage.setItem("jwt", idToken)
    await getUser()
  }, [oidcAxa, getUser])

  useEffect(() => {
    if (
      !cryptr.isAuthenticated() &&
      !cryptr.isLoading &&
      !auth0.isLoading &&
      !auth0.isAuthenticated &&
      !oidcAxa.isLoading &&
      !oidcAxa.isAuthenticated &&
      !["/unauthorized", "/forbidden", "/company-not-found", "/user-not-found", "/bot", "/learning-page"].includes(
        location.pathname
      )
    ) {
      const state = searchParams.get("state")
      const code = searchParams.get("code")
      if (state === null) {
        // Memoized current url for redirection after login
        if (shouldThePathBeMemoized(location.pathname)) {
          localStorage.setItem("redirectTo", location.pathname)
        }
        navigate(`/login${document.location.search}`)
      } else {
        window.location.replace(`/organization?vantaState=${state}&code=${code}`)
      }
    } else if (cryptr.isAuthenticated()) {
      RequestAccessTokenCryptr()
    } else if (auth0.isAuthenticated) {
      RequestAccessTokenAuth0()
    } else if (oidcAxa.isAuthenticated) {
      requestIDTokenOidcAXA()
    }
  }, [
    cryptr,
    auth0,
    navigate,
    RequestAccessTokenAuth0,
    RequestAccessTokenCryptr,
    searchParams,
    location.pathname,
    oidcAxa,
    requestIDTokenOidcAXA,
  ])

  useEffect(() => {
    if (typeof user.scope !== "undefined") {
      // if user fully loaded
      sha1(user.email).then(identifier => {
        const scopeEnum = Scope
        posthog.register({
          // Capturing this numerical value for every event.
          // It should be captured as a person property instead, but for now
          // we have not been able to compute metrics based on a numerical
          // person property in PostHog (besides using HogSQL).
          company_total_users: user.company.total_user,
        })
        posthog.identify(identifier, {
          company_name: user.company.name,
          company_plan: getCompanyPlan(user.company.status),
          company_total_users: user.company.total_user,
          team_name: user.team.name,
          team_total_users: user.team.user_count,
          user_role: scopeEnum[user.scope ?? scopeEnum.USER],
        })
        posthog.group("Company", `id:${user.company.id}`, {
          name: user.company.name,
        })
      })
    }
  }, [user])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (loaded && config.env !== "development") {
      faro.api.setUser({
        email: user.email,
        id: user.id.toString(),
        attributes: {
          company_id: user.company.id.toString(),
          company_name: user.company.name,
        },
      })
    }
  }, [loaded, user.email, user.id, user.company.id, user.company.name])
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    // IF user fully loaded AND featureFlag fully loaded AND we are not in a LoginPage popup
    if (
      typeof user.scope !== "undefined" &&
      featureFlag.length > 0 &&
      (window.opener === null || window.name !== "LoginPopup")
    ) {
      if (!rebrandingDisplay && !user.avatar) {
        navigate("/welcome")
      } else {
        if (location.pathname === "/") {
          // Redirect the user
          const redirection = localStorage.getItem("redirectTo")
          if (redirection !== null) {
            localStorage.removeItem("redirectTo")
            navigate(redirection ?? "")
          } else {
            if (scopeHas(user.scope, Scope.COMPANY_ADMIN)) {
              if (user.company.onboarding_completed) {
                navigate("/supervision")
              } else {
                navigate("/organization")
              }
            } else {
              navigate("/home")
            }
          }
        }
      }
      setTimeout(() => {
        setLoaded(true)
      }, 500)
    }
  }, [
    featureFlag.length,
    navigate,
    featureFlag,
    location.pathname,
    user.avatar,
    user.company.onboarding_completed,
    user.scope,
  ])

  useEffect(() => {
    sha1(user.email).then(identifier => {
      posthog.identify(identifier)
      posthog.capture("$pageview")
    })
    window.scrollTo({ top: 0 })
  }, [user.email])

  useEffect(() => {
    getUser()
  }, [getUser])

  return (
    <RebrandedApp
      loaded={loaded}
      toastRef={ref}
      doReload={doElearningReload}
      elearningReload={elearningReload}
      featureFlag={featureFlag}
      getUser={getUser}
      displayNewBeltModal={displayNewBelt}
      setDisplayNewBeltModal={setDisplayNewBelt}
    />
  )
}
