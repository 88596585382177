import type TUser from "@kamae-apps/shared/Types/TUser"
import { apiRequest } from "@kamae-apps/shared/utils"

class UserService {
  fetchUserData(): Promise<TUser> {
    try {
      return apiRequest<TUser>("/user?v2=true", { name: "v2" })
    } catch (error) {
      throw new Error(error)
    }
  }

  fetchUserV1Data(): Promise<TUser> {
    try {
      return apiRequest<TUser>("/user", { name: "v2" })
    } catch (error) {
      throw new Error(error)
    }
  }

  updateUserData(body: TUser): Promise<TUser> {
    try {
      return apiRequest("/user?v2=true", {
        name: "v2",
        method: "PUT",
        body,
      })
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default new UserService()
