import { IconButton } from "@kamae-apps/shared/Components/Atoms/IconButton/IconButton"
import { FC, ReactNode } from "react"
import style from "./Standard.module.css"

type ModalVariants = "modal-sm" | "modal-md"

type ModalProps = {
  variant: ModalVariants
  display: boolean
  title: string
  subTitle?: string
  tag?: ReactNode
  close: () => void
  children: ReactNode
  primaryButton?: ReactNode
  secondaryButton?: ReactNode
  lightButton?: ReactNode
}

export const Modal: FC<ModalProps> = ({
  variant,
  display,
  title,
  subTitle,
  tag,
  close,
  children,
  primaryButton,
  secondaryButton,
  lightButton,
}) => {
  return (
    <div
      className={style["modal-container"]}
      data-display={display}
      onClick={close}
    >
      <div
        className={style[variant]}
        onClick={e => e.stopPropagation()}
      >
        <div className={style["modal-header"]}>
          <div className={style["modal-header-content"]}>
            <div className={style["modal-header-content-title"]}>
              <h3>{title}</h3>
              {tag}
            </div>
            {subTitle && <p className={style["modal-header-content-subtitle"]}>{subTitle}</p>}
          </div>
          <div className={style["modal-header-close"]}>
            <IconButton
              variant={"button-small-light"}
              icon={"cross"}
              onClick={close}
            />
          </div>
        </div>
        <div className={style["modal-content"]}>{children}</div>
        <div className={style["modal-footer"]}>
          {lightButton}
          {secondaryButton}
          {primaryButton}
        </div>
      </div>
    </div>
  )
}
