import { Button } from "@kamae-apps/shared/Components/Atoms/Button/Button"
import { Tatami } from "@kamae-apps/shared/Components/Atoms/Tatami/Tatami"
import { useError404Page } from "./useError404Page"

const Error404Page = () => {
  const { t, navigate } = useError404Page()

  return (
    <div className={"fixed z-30 top-0 left-0 w-full h-full bg-gradient-ocean p-4"}>
      <div
        className={
          "relative glasseffect-xl bg-neutral-white/50 rounded-xl h-full w-full flex justify-center items-center overflow-hidden"
        }
      >
        <Tatami className={"invisible md:visible absolute bottom-14 text-[#DBDBDB] no-fill w-11/12"} />
        <div className={"w-2/3 xl:w-1/2 flex flex-col lg:flex-row justify-between items-center gap-10 z-10"}>
          <div className={"flex flex-col gap-5 items-start"}>
            <h1 className={"display-md-bold text-neutral-gray-900"}>{t("error404:title")}</h1>
            <p className={"body-md-regular text-neutral-gray-600"}>{t("error404:subtitle")}</p>
            <Button
              variant={"button-primary-initial-big"}
              onClick={() => navigate("/home")}
            >
              {t("error404:action")}
            </Button>
          </div>
          <img
            src={"404_error.png"}
            alt={"Error 404"}
          />
        </div>
      </div>
    </div>
  )
}

export default Error404Page
