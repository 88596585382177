import { useApi } from "@kamae-apps/shared/Hooks/useApi"
import { getBeltColor } from "@kamae-apps/shared/Types/Belt/Belt"
import { useCallback } from "react"
import { useUser } from "../Contexts/User/UserContext"
import config from "../variable"

export const useAvatars = () => {
  const { user } = useUser()
  const { data: avatars } = useApi<string[]>("/avatar", { queryParams: { v2: true } })

  const getAvatarUrl = useCallback(
    (avatar?: string) => {
      return avatar ? `${config.api}/avatar/${avatar}/${getBeltColor(user.level ?? 0)}` : "/avatarV2Unknown.png"
    },
    [user]
  )
  const getRoundedAvatarUrl = useCallback((avatar?: string) => {
    return avatar ? `${config.api}/avatar/${avatar}/rounded` : "/roundedAvatarV2Unknown.png"
  }, [])

  return {
    avatars,
    getAvatarUrl,
    getRoundedAvatarUrl,
  }
}
