import { Button } from "@kamae-apps/shared/Components/Atoms/Button/Button"
import { Card } from "@kamae-apps/shared/Components/Atoms/Card/Card"
import { Tatami } from "@kamae-apps/shared/Components/Atoms/Tatami/Tatami"
import { Trans } from "react-i18next"
import FlagDropdown from "./Legacy/FlagDropdown"
import { useLearningPage } from "./useLearningPage"

const LearningPage = () => {
  const { t, navigate, redirect } = useLearningPage()

  return (
    <div
      className={
        "relative bg-neutral-white md:bg-gradient-onsen w-screen h-screen flex md:justify-center md:items-center"
      }
    >
      <Tatami className={"hidden md:block absolute bottom-0 text-neutral-gray-400 no-fill w-11/12"} />
      <div className={"absolute top-0 right-0 flex gap-6 p-4 items-center z-20"}>
        <img
          src={"kamaeLogo.svg"}
          alt={"Kamaé"}
        />
        <FlagDropdown allLangs={true} />
      </div>
      <Card
        className={
          "flex flex-col max-w-[460px] p-4 md:p-6 gap-6 z-10 border-none md:border-neutral-gray-100 mt-20 md:mt-0"
        }
      >
        <div className={"rounded-xl bg-gradient-metallic flex justify-center items-center py-5"}>
          <img
            src={"/illustration/phishing.png"}
            alt={"phishing"}
          />
        </div>
        <div className={"flex flex-col gap-4 text-neutral-gray-900"}>
          <h3 className={"title-lg-semibold"}>{t("learningPage:rebranding.title")}</h3>
          <p className={"body-sm-regular"}>
            <Trans
              t={t}
              ns={"learningPage"}
              i18nKey={"rebranding.description"}
            />
          </p>
          <Button
            variant={"button-primary-initial-default"}
            onClick={() => navigate(redirect)}
          >
            {t("learningPage:rebranding.action")}
          </Button>
        </div>
        <p className={"caption-regular text-neutral-gray-600"}>{t("learningPage:rebranding.warning")}</p>
      </Card>
    </div>
  )
}

export default LearningPage
